import React from 'react';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-3 px-8 lg:px-12`,
  xl: `py-3 px-12 lg:py-5 lg:px-16 text-lg`
};

const ButtonLink = ({ children, className = '', size, href }) => {
  return (
    <a
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        font-nunito
        font-medium
        bg-white
        hover:bg-highlight
        text-highlight
        hover:text-white
        rounded
        border-2
        border-highlight
    `}
      href={href}
    >
      {children}
    </a>
  );
};

export default ButtonLink;
