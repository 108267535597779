import React from 'react';
import { Link } from 'react-scroll';
import { FormattedMessage, IntlContext } from 'gatsby-plugin-intl';

import Logo from '../../images/logo.png';
import ButtonLink from '../buttons/ButtonLink';

const isSubPage = () => window.location.pathname.match(/^\/\w{2}\/\w/);

const Header = () => {
  const { locale } = React.useContext(IntlContext);
  return (
    <header className="font-nunito sticky top-0 bg-background shadow z-50">
      <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
        <div className="flex items-center text-2xl">
          <img className="w-12 mr-3" src={Logo} alt="" />
        </div>
        <div className="flex mt-4 sm:mt-0">
          <Link
            className={`${locale}`}
            to="features"
            spy
            smooth
            duration={500}
            onClick={() => {
              if (isSubPage()) window.location = `/${locale}`;
            }}
          >
            <FormattedMessage id="header.elements.features" />
          </Link>
          <Link
            className="mx-4"
            to="services"
            spy
            smooth
            duration={500}
            onClick={() => {
              if (isSubPage()) window.location = `/${locale}`;
            }}
          >
            <FormattedMessage id="header.elements.services" />
          </Link>
          <Link
            className="mx-4"
            to="testimonials"
            spy
            smooth
            duration={500}
            onClick={() => {
              if (isSubPage()) window.location = `/${locale}`;
            }}
          >
            <FormattedMessage id="header.elements.testimonials" />
          </Link>
        </div>
        <div className="hidden md:block">
          <ButtonLink
            size="default"
            className="text-sm"
            href="https://app.blood-pressure-tracking.com/login"
          >
            <FormattedMessage id="account.sign_in" />
          </ButtonLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
