import React from 'react';
import { FormattedMessage, IntlContext } from 'gatsby-plugin-intl';

const Footer = () => {
  const { locale } = React.useContext(IntlContext);

  return (
    <footer className="font-nunito text-primary-lighter bg-background shadow pt-12 lg:pt-30 pb-8">
      <div className="container mx-auto py-8 lg:py-16 px-3">
        <div className="flex -mx-3">
          <div className="flex-1 px-3">
            <h2 className="text-primary text-lg font-semibold">
              <FormattedMessage id="footer.about.title" />
            </h2>
            <p className="mt-5">
              <FormattedMessage id="footer.about.body">
                {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
              </FormattedMessage>
            </p>
          </div>
          <div className="flex-1 px-3">
            <h2 className="text-primary text-lg font-semibold">
              <FormattedMessage id="footer.legals.title" />
            </h2>
            <ul className="mt-4 leading-loose">
              <li>
                <a href={`/${locale}/imprint`}>
                  <FormattedMessage id="footer.legals.elements.imprint" />
                </a>
              </li>
              <li>
                <a href={`/${locale}/privacy-policy`}>
                  <FormattedMessage id="footer.legals.elements.privacy_policy" />
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="flex-1 px-3"> */}
          {/*  <h2 className="text-lg font-semibold">Social Media</h2> */}
          {/*  <ul className="mt-4 leading-loose"> */}
          {/*    <li> */}
          {/*      <a href="https://dev.to/changoman">Dev.to</a> */}
          {/*    </li> */}
          {/*    <li> */}
          {/*      <a href="https://twitter.com/HuntaroSan">Twitter</a> */}
          {/*    </li> */}
          {/*    <li> */}
          {/*      <a href="https://github.com/codebushi/gatsby-starter-lander">GitHub</a> */}
          {/*    </li> */}
          {/*  </ul> */}
          {/* </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
