import React from 'react';
import { FormattedMessage, IntlContext } from 'gatsby-plugin-intl';

const PrivacyModal = ({ selectMethodPrivacyModal }) => {
  const { locale } = React.useContext(IntlContext);
  return (
    <aside className="privacy-policy-modal border-highlight">
      <p className="privacy-policy-modal-text">
        <FormattedMessage id="privacy_policy_modal.text">
          {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
        </FormattedMessage>
      </p>
      <div className="privacy-policy-modal-buttons">
        <button
          type="button"
          className="
        privacy-policy-modal-button
        text-highlight
        border-2
        border-solid
        border-highlight
        rounded-3xl
        hover:bg-white
        hover:border-white
        "
          onClick={() => {
            selectMethodPrivacyModal(true);
          }}
        >
          <FormattedMessage id="privacy_policy_modal.accept" />
        </button>
        <button
          type="button"
          className="
        privacy-policy-modal-button
        text-highlight
        border-2
        border-solid
        border-highlight
        rounded-3xl
        hover:bg-white
        hover:border-white
        "
          onClick={() => {
            selectMethodPrivacyModal(false);
            window.location = `/${locale}/privacy-policy#privacy-policy-settings`;
          }}
        >
          <FormattedMessage id="privacy_policy_modal.decline" />
        </button>
      </div>
    </aside>
  );
};

export default PrivacyModal;
