import React from 'react';

const defaultState = {
  gtagOptIn:
    typeof window !== 'undefined' &&
    typeof window.localStorage !== 'undefined' &&
    window.localStorage.getItem('gtagOptIn') === 'true',
  setGtagOptIn: () => {
    console.log('Still using default');
  }
};

const GtagOptInContext = React.createContext(defaultState);
export const GtagOptInProvider = GtagOptInContext.Provider;
export default GtagOptInContext;
