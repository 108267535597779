import React, { useEffect, useMemo, useState } from 'react';
import { GTAG_OPTIN_KEY } from 'gatsby-plugin-google-gtag-optin'; // Or use your own if you changed it in the config

import Header from './Header';
import Footer from './Footer';
import { GtagOptInProvider } from './context';
import PrivacyModal from '../privacy-modal';

function getLocalStorage(key) {
  if (typeof window !== 'undefined' && window.localStorage) return window.localStorage.getItem(key);
  return undefined;
}
function setLocalStorage(key, value) {
  if (typeof window !== 'undefined' && window.localStorage)
    return window.localStorage.setItem(key, value);
  return undefined;
}

function checkLoadGtag() {
  if (typeof window !== 'undefined' && typeof window.loadGtag !== 'undefined') window.loadGtag();
}

const Layout = ({ children }) => {
  const isOptIn = getLocalStorage(GTAG_OPTIN_KEY) === 'true';
  const [gtagOptIn, setGtagOptIn] = useState(getLocalStorage(GTAG_OPTIN_KEY) === 'true');
  const [displayModal, setDisplayModal] = useState(getLocalStorage(GTAG_OPTIN_KEY) !== 'true');

  /** didUpdate - gtagOptIn */
  useEffect(() => {
    if (gtagOptIn) {
      setLocalStorage(GTAG_OPTIN_KEY, 'true');
      checkLoadGtag();
      setDisplayModal(false);
    } else setLocalStorage(GTAG_OPTIN_KEY, 'false');
  }, [gtagOptIn]);

  /** didUpdate - displayModal */
  useEffect(() => {
    console.log('/** didUpdate - displayModal */', displayModal);
  }, [displayModal]);

  const selectMethodPrivacyModal = bool => {
    console.log('selectMethodPrivacyModal >> ', bool);
    setDisplayModal(false);
    setGtagOptIn(bool);
  };

  return useMemo(
    () => (
      <GtagOptInProvider value={{ gtagOptIn, setGtagOptIn }}>
        <Header />
        <main className="text-dark bg-background-lighter font-nunito">{children}</main>
        <Footer />
        {displayModal && <PrivacyModal selectMethodPrivacyModal={selectMethodPrivacyModal} />}
      </GtagOptInProvider>
    ),
    [gtagOptIn, displayModal]
  );
};

export default Layout;
